import React, { useEffect, useState } from "react";
import OverlayBox from "../others/overlayBox";
import style from "./settings.module.css";
import { PiUserLight } from "react-icons/pi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlineLogout } from "react-icons/hi";
import Profile from "./profile";
import ResellerProfile from "./resellerProfile";
import About from "./about";
import Help from "./help";
import { useNavigate } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const Settings = (props) => {
  const navigate = useNavigate();
  const role = useSelector((state) => state.authStore.role);
  const settingsMenu = [
    { title: "Profile", value: "profile", icon: <PiUserLight /> },
    // { title: "About", value: "about", icon: <AiOutlineExclamationCircle /> },
    { title: "Help", value: "help", icon: <FiHelpCircle /> },
  ];
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("profile");
  return (
    <>
      <OverlayBox
        open={props.onOpen}
        close={props.onClose}
        title="Settings"
        customClass={style.overlayBoxCustom}
        customBodyClass={style.customBodyClass}
      >
        <div className={style.setting_section}>
          <div className={style.menu_section}>
            <div
              className={style.menu_mobile}
              onClick={() => setMenuOpen((prev) => !prev)}
            >
              {!menuOpen ? <IoMenuOutline /> : <IoCloseOutline />} Menu
            </div>
            <ul className={`${menuOpen ? style.menuOpen : style.menuClose}`}>
              {settingsMenu.map((item, index) => (
                <li
                  className={`${activeMenu === item.value ? style.active : ""}`}
                  key={index}
                  onClick={() => setActiveMenu(item.value)}
                >
                  {item.icon}
                  {item.title}
                </li>
              ))}
              <li onClick={() => navigate("/logout")}>
                <HiOutlineLogout /> Logout
              </li>
            </ul>
          </div>
          <div className={style.setting_body}>
            {role === "admin" && activeMenu === "profile" && <Profile />}
            {role === "reseller" && activeMenu === "profile" && (
              <ResellerProfile />
            )}
            {activeMenu === "about" && <About />}
            {activeMenu === "help" && <Help />}
          </div>
        </div>
      </OverlayBox>
    </>
  );
};

export default Settings;
