import React, { useEffect, useState } from "react";
import style from "./notificationBox.module.css";
import api from "../../scripts/api";
import moment from "moment";
import Loader from "../utils/loader";
import BtnLoader from "../utils/btnLoader";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../../customHooks/useNotification";
import { loadNotificationCountWithoutReload } from "../../store/index";
import { socket } from "../../scripts/socket";

const NotificationBox = (props) => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({ data: [] });
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();
  const loadData = async () => {
    setLoader(true);
    await api
      .get(
        "/notification/logs?limit=10&&page=" +
          1 +
          "&params=" +
          JSON.stringify({ read: false })
      )
      .then((res) => {
        setLoader(false);
        setData(res.data.result);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const loadDataWithoutReload = async () => {
    await api
      .get(
        "/notification/logs?limit=10&&page=" +
          1 +
          "&params=" +
          JSON.stringify({ read: false })
      )
      .then((res) => {
        setData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socket.on(
      `${process.env.REACT_APP_PRODUCT_ID}_${props.loaderData.company._id}_reload_notification`,
      (message) => {
        loadDataWithoutReload();
      }
    );
    return () => {
      socket.off(
        `Disconnected ${process.env.REACT_APP_PRODUCT_ID}_${props.loaderData.company._id}_reload_notification`
      );
    };
  }, []);

  const onMarkas = async (id, index) => {
    await api
      .post("/notification/markAsRead", {
        _id: id,
        read: true,
      })
      .then((res) => {
        if (res.data.success === true) {
          setData((prev) => {
            let newObject = { ...prev };
            newObject.data.splice(index, 1);
            return newObject;
          });
          loadNotificationCountWithoutReload();
        }
      })
      .catch((err) => {});
  };

  const onMarkallas = async () => {
    setBtnLoader(true);
    await api
      .post("/notification/markAllAsRead")
      .then(async (res) => {
        if (res.data.success === true) {
          createNotification("success", "Successfully Marked All As Read");
          loadData();
          setData({ data: [] });
          loadNotificationCountWithoutReload();
        } else {
          createNotification("warning", "All Are Already Marked As Read");
        }
        setBtnLoader(false);
      })
      .catch((err) => {
        setBtnLoader(false);
        createNotification("error", "Failed To Mark All As Read");
      });
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className={style.notification_box}>
      {/* <div className={style.notification_head}>Notifications</div> */}
      <div className={style.notification_body}>
        {!loader && data.data.length > 0 ? (
          <div className={style.notification_table}>
            {data.data.map((item, index) => (
              <div
                className={style.notification_table_data}
                onClick={() => {
                  if (item.type === "order") {
                    navigate("/admin/orders/" + item.type_id);
                  } else if (item.type === "product") {
                    navigate("/admin/products/edit/" + item.type_id);
                  } else if (item.type === "variant") {
                    navigate("/admin/variants/edit/" + item.type_id);
                  } else if (item.type === "review") {
                    navigate("/admin/reviews/edit/" + item.type_id);
                  } else if (item.type === "discount") {
                    navigate("/admin/discounts/edit/" + item.type_id);
                  }
                  onMarkas(item._id, index);
                  props.close();
                }}
              >
                <div className={style.content}>{item.notification}</div>
                <div className={style.date}>
                  {moment(item.created_at).format("MMM Do YYYY")}
                </div>
              </div>
            ))}
            {data.pageCount > 1 && (
              <div
                className={`secondary-btn ${style.load_btn}`}
                onClick={() => {
                  navigate("/admin/notification/logs?status=unread");
                  props.close();
                }}
              >
                Load More
              </div>
            )}
          </div>
        ) : (
          loader && <Loader className={style.loader} />
        )}
        {!loader && data.data.length === 0 && (
          <div className={style.no_unread}>No Unread Notifications</div>
        )}
      </div>
      {data && data.data && data.data.length > 0 && (
        <div className={style.notification_footer}>
          {!btnLoader ? (
            <button
              className={`primary-btn ${style.read_btn}`}
              type="button"
              onClick={() => onMarkallas()}
            >
              Mark All As Read
            </button>
          ) : (
            <button className={`primary-btn ${style.read_btn}`} type="button">
              <BtnLoader className={style.btn_loader} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationBox;
