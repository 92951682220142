import React, { useEffect, useMemo, useState } from "react";
import style from "./profile.module.css";
import InputText from "../utils/inputText";
import api from "../../scripts/api";
import { createNotification } from "../../customHooks/useNotification";
import Loader from "../utils/loader";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../utils/btnLoader";
import { authStoreActions } from "../../store/auth";
import ResetPassword from "./resetPassword/resetPassword";
import SearchSelect from "../utils/searchSelect";

const Profile = (props) => {
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState({});
  // const [name, setName] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const [resetBoxOpen, setResetBoxOpen] = useState(false);
  const [countryLoader, setCountryLoader] = useState(true);
  const [countriesOption,setCountryOption] = useState([]);


    const loadCountryData = async (searchValue = "") => {
    setCountryLoader(true);
    await api
      .get("/country")
      .then((res) => {
        setCountryOption(() => {
          let newArray = [];
          if (res.data.data.length > 0)
            for (const item of res.data.data) {
              newArray.push({
                title: item.name,
                value: item.name.toLowerCase(),
              });
            }
          return newArray;
        });
        setCountryLoader(false);
      })
      .catch((err) => {
        setCountryLoader(false);
        console.log(err);
      });
  };


  const companyID = useSelector((state) => state.authStore.company_id);
  useEffect(() => {
    loadCountryData();
    onLoadData();
  }, []);


  const onLoadData = async () => {
    try {
      setLoader(true);
      await api
        .get("/user")
        .then(async (res) => {
          setLoader(false);
          if (res.data && res.data.data && res.data.success === true) {
            setUserData(res.data.data);
            // setName(res.data.data.name);
          }
        })
        .catch((err) => {
          createNotification("error", "Something Went Wrong");
          setLoader(false);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveData = async () => {
    setError("");
    if (!userData.name) {
      setError({ name: "Name is required" });
      return;
    }
    if (!userData.phone) {
      setError({ phone: "Phone number is required" });
      return
    }
    if (userData.phone < 1111111111) {
      setError({ phone: "Phone number needs to be valid" });
      return
    }
    try {
      setBtnLoader(true);
      await api
        // .post("/user/name", { name: userData.name })
        .post("/user/update",{...userData})
        .then(async (res) => {
          setBtnLoader(false);
          dispatch(authStoreActions.updateAuthData({ name: userData.name }));
          createNotification("success", "Updated Successfully");
        })
        .catch((err) => {
          createNotification("error", "Something Went Wrong");
          setBtnLoader(false);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className={style.profile_section}>
        <h1 className={style.heading}>Profile</h1>
        {!loader ? (
          <div className={style.body}>
            <div className={style.section}>
              <div className={style.content}>
                <div className={style.grid}>
                  <InputText
                    label="User ID"
                    value={userData._id}
                    disabled={true}
                  />
                  <InputText
                    label="Company ID"
                    value={companyID}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className={style.section}>
              <p>Account</p>
              <hr />
              <div className={style.content}>
                <div className={style.grid}>
                  <InputText
                    label="Name"
                    getValue={(value) => setUserData({ ...userData, name: value })}
                    value={userData.name}
                    required={true}
                    error={error.name}
                  />
                  <InputText
                    label="Email"
                    value={userData.email}
                    disabled={true}
                  />
                  <InputText
                    label="Phone"
                    value={userData.phone}
                    maxLength={13}
                    minLength={10}
                    getValue={(value) => setUserData({ ...userData, phone: value })}
                    disabled={false}
                    error={error.phone}
                    required={true}
                  />
                  <SearchSelect
                    setOption={countriesOption}
                    placeholder={`Select Country`}
                    label="Country"
                    loader={countryLoader}
                    error={error["country"]}
                    setValue={userData.country}
                    getValue={(value) => {
                      setUserData((prev) => {
                        let newArray = { ...prev };
                        newArray.country = value.value;
                        return newArray;
                      });
                      if (value) {
                        setError((prev) => {
                          let newArray = { ...prev };
                          newArray["country"] = null;
                          return newArray;
                        });
                      }
                    }}
                    // customCssForBtn={style.customCountrySelectCss}
                    value={userData.country.charAt(0).toUpperCase() + userData.country.slice(1)}
                    disabled={false}
                  />
                </div>
                {!btnLoader ? (
                  <button
                    type="button"
                    className={`primary-btn ${style.updateBtn}`}
                    onClick={() => onSaveData()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className={`primary-btn ${style.updateBtn}`}
                    type="button"
                  >
                    <BtnLoader className={style.btnLoader} />
                  </button>
                )}
              </div>
            </div>
            <div className={style.section}>
              <p>Reset Password</p>
              <hr />
              <div className={style.btn_section}>
                <button
                  className={`secondary-btn ${style.resetBtn}`}
                  type="button"
                  onClick={() => setResetBoxOpen(true)}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Loader className={style.loader} />
        )}
      </div>
      <ResetPassword
        onOpen={resetBoxOpen}
        onClose={() => setResetBoxOpen(false)}
      />
    </>
  );
};

export default Profile;
