import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: null,
  isLoggedIn: false,
  name: "",
  currencyType: "aed",
  monthly_target: {
    revenue: 0,
    earnings: 0,
    customers: 0,
    orders: 0,
  },
  theme_mode: "light",
  settingsBox: false,
  orderStatusCount: {},
  file_url: {},
};
const generalStore = createSlice({
  name: "generalStoreAction",
  initialState: initialState,
  reducers: {
    updateAuthData(state, action) {
      if (action.payload.role) state.role = action.payload.role;
      if (action.payload.isLoggedIn)
        state.isLoggedIn = action.payload.isLoggedIn;
      if (action.payload.name) state.name = action.payload.name;
    },
    clearAuthData(state) {
      state.role = null;
      state.isLoggedIn = null;
    },
    updateRole(state, action) {
      state.role = action.payload.role;
    },
    updateFileUrl(state, action) {
      state.file_url = action.payload.file_url;
    },
    updateSettingsBox(state, action) {
      state.settingsBox = action.payload.settingsBox;
    },
    updateThemeMode(state, action) {
      state.theme_mode = action.payload.theme_mode;
    },
    updateCurrencyType(state, action) {
      state.currencyType = action.payload.currencyType;
    },
    updateMonthlyTarget(state, action) {
      if (action.payload.monthly_target)
        state.monthly_target = action.payload.monthly_target;
    },
    updateLoggedIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    updateOrderStatusCount(state, action) {
      state.orderStatusCount = action.payload.orderStatusCount;
    },
  },
});

export const generalStoreActions = generalStore.actions;

export default generalStore;
