import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API,
});

export const carCatalogApi = axios.create({
  baseURL: process.env.REACT_APP_CAR_CATALOG_API,
});

export default api;
