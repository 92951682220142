import React from "react";

const useEmailValidation = (email) => {
  try {
    if (!email) {
      const result = { value: false, reason: "Empty Data" };
      throw result;
    }
    // Atleast one special character, one numeric, one capital letter, 10 minimum length & maximum 20 length
    const validationRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(validationRegex)) {
      return { value: true, reason: "Validation Success" };
    } else {
      const result = { value: false, reason: "Invalid Email" };
      throw result;
    }
  } catch (err) {
    return err;
  }
};

const usePasswordValidation = (password) => {
  try {
    if (!password) {
      const result = { value: false, reason: "Empty Data" };
      throw result;
    }
    // Atleast one special character, one numeric, one capital letter, 10 minimum length & maximum 20 length
    const validationRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,20}$/;
    if (password.match(validationRegex)) {
      return { value: true, reason: "Validation Success" };
    } else {
      const result = { value: false, reason: "Invalid Password" };
      throw result;
    }
  } catch (err) {
    return err;
  }
};

const usePhoneValidation = (phone) => {
  try {
    if (!phone) {
      const result = { value: false, reason: "Empty Data" };
      throw result;
    }
    console.log("phone", phone);
    const validationRegex = /^\(?([0-9+]{10,13})$/;
    if (String(phone).match(validationRegex)) {
      return { value: true, reason: "Validation Success" };
    } else {
      const result = { value: false, reason: "Invalid Phone Number" };
      throw result;
    }
  } catch (err) {
    return err;
  }
};

export { useEmailValidation, usePasswordValidation, usePhoneValidation };
