import { io } from "socket.io-client";

const URL = process.env.REACT_APP_SOCKETIO;
const socket = io(URL);

// socket.on("connect", () => {
//   console.log("Socket Connect ", socket.id);
// });

// socket.on("disconnect", () => {
//   console.log("Socket Disconnect ", socket.id);
// });
export { socket };
