import React from "react";
const BtnLoader = (props) => {
  return (
    <div className={`${props.className && props.className}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 35 55 35">
        <circle
          fill={props.color ? props.color : "#fff"}
          stroke="none"
          cx="6"
          cy="50"
          r="6"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle
          fill={props.color ? props.color : "#fff"}
          stroke="none"
          cx="26"
          cy="50"
          r="6"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle
          fill={props.color ? props.color : "#fff"}
          stroke="none"
          cx="46"
          cy="50"
          r="6"
        >
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
  );
};

export default BtnLoader;
