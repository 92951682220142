import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generalStoreActions } from "../../store/general";

const SetThemeType = (props) => {
  const themeMode = useSelector((state) => state.generalStore.theme_mode);
  const dispatch = useDispatch();

  useMemo(() => {
    if (localStorage.getItem("theme_mode")) {
      dispatch(
        generalStoreActions.updateThemeMode({
          theme_mode: ["dark", "light"].includes(
            localStorage.getItem("theme_mode")
          )
            ? localStorage.getItem("theme_mode")
            : "light",
        })
      );
    } else {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        dispatch(generalStoreActions.updateThemeMode({ theme_mode: "dark" }));
      }
    }
  }, []);

  useMemo(() => {
    document.documentElement.setAttribute("data-theme", themeMode);
  }, [themeMode]);
  return;
};

export default SetThemeType;
