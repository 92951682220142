import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: null,
  isLoggedIn: false,
  company_id: null,
  company_name: null,
  product_id: null,
  name: "",
  onboard: true,
};
const authStore = createSlice({
  name: "authStoreAction",
  initialState: initialState,
  reducers: {
    updateAuthData(state, action) {
      if (action.payload.role) state.role = action.payload.role;
      if (action.payload.isLoggedIn)
        state.isLoggedIn = action.payload.isLoggedIn;
      if (action.payload.product_id)
        state.product_id = action.payload.product_id;
      if (action.payload.company_id)
        state.company_id = action.payload.company_id;
      if (action.payload.company_name)
        state.company_name = action.payload.company_name;
      if (action.payload.name) state.name = action.payload.name;
      console.log({ "authstore": action.payload.onboard,type:typeof action.payload.onboard })
      if (typeof action.payload.onboard === "boolean") state.onboard = action.payload.onboard ? true : false;
    },
    clearAuthData(state) {
      state.role = null;
      state.isLoggedIn = null;
    },
    updateRole(state, action) {
      state.role = action.payload.role;
    },
    updateLoggedIn(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    updateOnboard(state, action) {
      state.onboard = action.payload.onboard;
    }
  },
});

export const authStoreActions = authStore.actions;

export default authStore;
