import React, { useEffect, useState, useRef } from "react";
import style from "./inputText.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineEditOff } from "react-icons/md";

const InputText = (props) => {
  const [value, setValue] = useState(props.value ? props.value : "");
  const [error, setError] = useState(null);
  const ref = useRef();

  const validation = (value) => {
    if (props.type === "number") {
      console.log("typeof parseFloat(value), ", typeof parseFloat(value));
      if (!props.cancelInValidation) {
        if (isNaN(parseInt(value))) {
          setValue();
          props.getValue();
          ref.current.value = "";
          return false;
        }
      }
      if (value === "") {
        props.getValue && props.getValue(value);
        setValue(value);
        return;
      }
      if (typeof props.min === "number" && props.min > value) {
        ref.current.value = props.min;
      } else if (props.max && props.max < value) {
        ref.current.value = props.max;
      } else {
        props.getValue && props.getValue(value);
        setValue(value);
      }
    } else {
      // console.log("value: ", value);
      if (props.getValue) props.getValue(value);
      setValue(value);
    }
  };
  useEffect(() => {
    console.log("props.value: ", props.value);
    setValue(props.value);
  }, [props.value]);

  return (
    <>
      <div
        className={`${style.inputBox} ${
          props.customClass && props.customClass
        } ${props.label && style.label} ${props.customCss && props.customCss}`}
      >
        {props.label && (
          <label htmlFor={props.id}>
            {props.icon && props.icon}
            {props.label} {props.required === true && <sup>*</sup>}
          </label>
        )}
        <div
          className={`${style.input_content_section}  ${
            props.error && style.error
          } ${props.customInputClass && props.customInputClass}`}
        >
          {props.inputType === "textarea" ? (
            <textarea
              placeholder={props.placeholder}
              name={props.name}
              required={props.required}
              ref={ref}
              cols={props.cols}
              rows={props.rows}
              id={props.id}
              onInput={(e) => {
                validation(e.target.value);
              }}
              disabled={props.disabled}
            >
              {value}
            </textarea>
          ) : (
            <input
              type={`${props.type ? props.type : "text"}`}
              placeholder={props.placeholder && props.placeholder}
              value={value}
              name={props.name}
              required={props.required}
              min={props.min}
              max={props.max}
              maxLength={props.maxLength}
              minLength={props.minLength}
              ref={ref}
              id={props.id}
              onInput={(e) => {
                validation(e.target.value);
              }}
              disabled={props.disabled}
              pattern={`${props.pattern ? "[0-9]+([.,][0-9]+)?" : "(?s).*"}`}
              step={props.step ? props.step : "any"}
            />
          )}

          {(props.error || props.message || props.disabled) && (
            <div className={style.input_details}>
              {props.disabled && (
                <p className={`${style.disable_style}`}>
                  <MdOutlineEditOff />
                </p>
              )}
              {props.message && (
                <p
                  className={`${style.message} ectooltip`}
                  tooltip-text={props.message}
                  tooltip-position={
                    props.tooltipPosition ? props.tooltipPosition : "left"
                  }
                >
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </p>
              )}
              {props.error ? (
                <p
                  className={`${style.errorText} ectooltip ${
                    props.error && props.error.length === 0 ? style.hidebox : ""
                  }`}
                  tooltip-text={props.error}
                  tooltip-position={
                    props.tooltipPosition ? props.tooltipPosition : "left"
                  }
                >
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                </p>
              ) : (
                error && (
                  <p
                    className={`${style.errorText} ectooltip`}
                    tooltip-text={error}
                    tooltip-position={
                      props.tooltipPosition ? props.tooltipPosition : "left"
                    }
                  >
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </p>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InputText;
