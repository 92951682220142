import React, { useEffect, useState } from "react";
import style from "./header.module.css";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import { PiUserLight } from "react-icons/pi";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/utils/loader";
import NotificationBox from "../../components/header/notificationBox";
import InputText from "../../components/utils/inputText";
import LightDarkToggleSwitch from "../../components/utils/lightDarkToggleSwitch";
import MenuSearch from "../../components/utils/menuSearch";
import { generalStoreActions } from "../../store/general";
import ResellerNotificationBox from "../../components/header/resellerNotificationBox";

const Header = (props) => {
  const [notificationShow, setNotificationShow] = useState(false);
  const [search, setSearch] = useState();
  const role = useSelector((state) => state.authStore.role);
  const navbar = [
    { to: "/", name: "Home", title: "" },
    { to: "/category", name: "Category" },
    { to: "/products", name: "Products" },
  ];
  const dispatch = useDispatch();
  const notificationCount = useSelector(
    (state) => state.notificationCountStore.count
  );
  const username = useSelector((state) => state.authStore.name);
  const company_name = useSelector((state) => state.authStore.company_name);
  const notificationLoader = useSelector(
    (state) => state.notificationCountStore.loader
  );
  const onboard = useSelector((state) => state.authStore.onboard);
  useEffect(() => {
    console.log({ header: onboard });
  }, [onboard]);
  return (
    <React.Fragment>
      <header>
        <div className={style.header_row}>
          <div className={style.header_col_1}>
            <div className={style.logo}>
              <div className={style.logo_text_section}>
                {/* <h1>Ecommerce Manager</h1> */}
                {/* <p>
                  powered by <span>K</span>notHost
                </p> */}
                <div
                  className={style.search_box_section}
                  data-tg-title="Search"
                >
                  <MenuSearch />
                  <CiSearch />
                </div>
              </div>
              <div className={style.divider}></div>
              {company_name && (
                <span className={style.company_name} data-tg-title="Company">
                  {company_name}
                </span>
              )}
            </div>
          </div>
          <div className={style.header_col_2}>
            <li data-tg-title="Theme">
              <LightDarkToggleSwitch />
            </li>
            <li data-tg-title="Notifications">
              <button
                className="round-btn"
                onMouseEnter={() => setNotificationShow(true)}
                onMouseLeave={() => setNotificationShow(false)}
              >
                <IoIosNotificationsOutline />
                <div
                  className={style.total_count}
                  data-count={
                    notificationCount
                      ? notificationCount
                      : !notificationLoader
                      ? 0
                      : ""
                  }
                >
                  {notificationLoader && (
                    <Loader className={style.loader_notification} />
                  )}
                </div>
              </button>
              <div
                className={`${style.notification_box_section} ${
                  !notificationShow ? style.hide_notification : ""
                }`}
                onMouseEnter={() => setNotificationShow(true)}
                onMouseLeave={() => setNotificationShow(false)}
              >
                {role && role === "admin" && (
                  <NotificationBox
                    loaderData={props.loaderData}
                    close={() => setNotificationShow(false)}
                  />
                )}
                {role && role === "reseller" && (
                  <ResellerNotificationBox
                    loaderData={props.loaderData}
                    close={() => setNotificationShow(false)}
                  />
                )}
              </div>
            </li>
            <li className={style.divider_border_right}>
              <button
                className="round-btn"
                onClick={() =>
                  dispatch(
                    generalStoreActions.updateSettingsBox({
                      settingsBox: true,
                    })
                  )
                }
                data-tg-title="Settings"
              >
                <IoSettingsOutline />
              </button>
            </li>
            <li>
              <div className={style.profile_pic}>
                <PiUserLight />
                <span className={style.profile_name}> {username} </span>
              </div>
            </li>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
