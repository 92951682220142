import React, { useEffect, useState } from "react";
import style from "./menuSearch.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MenuSearch = (props) => {
  const [selectOption, setSelectionOption] = useState([
    { title: "Test", value: "test" },
    { title: "Test1", value: "test1" },
  ]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [randomID] = useState("id_" + Math.floor(Math.random() * 1000000 + 1));
  const role = useSelector((state) => state.authStore.role);

  const adminSearch = [
    { to: "/admin", name: "Dashboard" },
    { to: "/admin/category", name: "Category" },
    {
      to: "/admin/products",
      name: `Products`,
    },
    {
      to: "/admin/variants",
      name: `Variants`,
    },
    {
      to: "/admin/orders/all",
      name: `Orders - All`,
    },
    {
      to: "/admin/orders/pending",
      name: `Orders - Pending`,
    },
    {
      to: "/admin/orders/processing",
      name: `Orders - Processing`,
    },
    {
      to: "/admin/orders/completed",
      name: `Orders - Completed`,
    },
    {
      to: "/admin/orders/return",
      name: `Order Returns - All`,
    },
    {
      to: "/admin/orders/return_processing",
      name: `Order Returns - Processing`,
    },
    {
      to: "/admin/orders/return_completed",
      name: `Order Returns - Completed`,
    },
    {
      to: "/admin/orders/canceled",
      name: `Order Canceled - All`,
    },
    {
      to: "/admin/orders/not_refunded",
      name: `Order Canceled - Not Refunded`,
    },
    {
      to: "/admin/orders/refunded",
      name: `Order Canceled - Refunded`,
    },

    {
      name: "Reviews",
      to: "/admin/reviews",
    },
    {
      name: "Discounts",
      to: "/admin/discounts",
    },
    { to: "/admin/posts", name: "Post" },

    { to: "/admin/site/top-header", name: "Top Header" },
    { to: "/admin/site/slider", name: "Sliders" },
    { to: "/admin/site/content", name: "Contents" },
    { to: "/admin/popup/message", name: "Popup Message" },
    { to: "/admin/site/meta", name: "SEO Meta" },
    { to: "/admin/float/icons", name: "Float Icons" },
    { to: "/admin/site/social-media", name: "Social Media" },

    {
      name: "Recent Viewed",
      to: "/admin/product/recent/customer/logs",
    },
    { to: "/admin/subscriber/email", name: "Email" },
    {
      name: "Customers",
      to: "/admin/customers",
    },
    {
      name: "Resellers",
      to: "/admin/resellers",
    },
    {
      name: "Admins",
      to: "/admin/users",
    },

    { to: "/admin/settings/general", name: "General Settings" },
    { to: "/admin/settings/shipping", name: "Shipping Settings" },
    { to: "/admin/settings/invoice", name: "Invoice Settings" },
    { to: "/admin/settings/paytabs", name: "Paytabs Settings" },
    { to: "/admin/settings/stripe", name: "Stripe Settings" },
    { to: "/admin/settings/recaptcha", name: "Google Recaptcha Settings" },
    { to: "/admin/settings/smtp", name: "SMTP Server Settings" },
    { to: "/admin/settings/whatsapp", name: "WA API Settings" },
    {
      name: `Notifications`,
      to: "/admin/notification/logs",
    },
    { to: "/logout", name: "Sign Out" },
  ];

  const resellerSearch = [
    { to: "/reseller", name: "Dashboard" },
    {
      to: "/reseller/products",
      name: `Products`,
    },
    {
      to: "/reseller/pending/products",
      name: `Pending Products`,
    },
    {
      to: "/reseller/variants",
      name: `Variants`,
    },
    {
      to: "/reseller/orders/all",
      name: `Orders - All`,
    },
    {
      to: "/reseller/orders/pending",
      name: `Orders - Pending`,
    },
    {
      to: "/reseller/orders/processing",
      name: `Orders - Processing`,
    },
    {
      to: "/reseller/orders/completed",
      name: `Orders - Completed`,
    },
    {
      to: "/reseller/orders/return",
      name: `OrderReturns - All`,
    },
    {
      to: "/reseller/orders/return_processing",
      name: `Order Returns - Processing`,
    },
    {
      to: "/reseller/orders/return_completed",
      name: `Order Returns - Completed`,
    },
    {
      to: "/reseller/orders/canceled",
      name: `Order Canceled - All`,
    },
    {
      to: "/reseller/orders/not_refunded",
      name: `Order Canceled - Not Refunded`,
    },
    {
      to: "/reseller/orders/refunded",
      name: `Order Canceled - Refunded `,
    },
  ];

  useEffect(() => {
    if (role === "reseller") {
      setSelectionOption(resellerSearch);
    } else {
      setSelectionOption(adminSearch);
    }
  }, [role]);

  const dataFilter = (data) => {
    let filteredData = [];
    filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    console.log("filteredData: ", filteredData);
    if (filteredData.length > 0) {
      return filteredData;
    } else return [];
  };

  useEffect(() => {
    const clickListener = (e) => {
      if (
        document.getElementsByClassName(randomID) &&
        !document.getElementById(randomID).contains(e.target)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className={`${style.search_select} ${
          props.customCss && props.customCss
        } ${props.disabled ? style.disabled : ""}`}
        id={randomID}
      >
        <div
          className={`${style.search_btn} ${
            props.customCssForBtn ? props.customCssForBtn : ""
          } ${props.error ? style.error : ""}`}
          onClick={() => {
            if (!props.disabled) setOpen((prev) => !prev);
          }}
        >
          <div className={style.selected_data_section}>
            <input
              type="text"
              placeholder={"Search Here"}
              value={search}
              onInput={(e) => {
                setSearch(e.target.value);
                if (e.target.value) {
                  setOpen(true);
                }
              }}
            />
          </div>
        </div>
        {open && search && (
          <ul className={style.select_list}>
            {dataFilter(selectOption).map((item, index) => (
              <li
                onClick={() => {
                  navigate(item.to);
                  setOpen(false);
                  setSearch("");
                }}
                key={index}
              >
                {item && item.name}
              </li>
            ))}
            {dataFilter(selectOption).length === 0 && (
              <p className={style.message}>Empty</p>
            )}
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};

export default MenuSearch;
