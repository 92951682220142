import React, { useState } from "react";
import style from "./resetPassword.module.css";
import InputText from "../../utils/inputText";
import OverlayBox from "../../others/overlayBox";
import { usePasswordValidation } from "../../../customHooks/useValidation";
import { authApi } from "../../../scripts/api";
import { createNotification } from "../../../customHooks/useNotification";
import BtnLoader from "../../utils/btnLoader";

const ResetPassword = (props) => {
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [btnLoader, setBtnLoader] = useState(false);
  const [error, setError] = useState("");
  const passwordValidation = usePasswordValidation(passwordData.new_password);
  const onUpdate = async () => {
    setBtnLoader(true);
    let errorFound = false;
    if (!passwordData.old_password) {
      setError("Old Password Empty");
      errorFound = true;
    }
    if (passwordValidation.value === false) {
      setError("Incorrect Password Format");
      errorFound = true;
    }
    if (passwordData.new_password !== passwordData.confirm_new_password) {
      setError("Password Doesnt Match");
      errorFound = true;
    }
    console.log("passwordData,", passwordData);
    if (errorFound === false) {
      await authApi
        .post("/product/user/password/change", { ...passwordData })
        .then((res) => {
          if (res.data.success === true) {
            createNotification("success", "Password Updated Successfully");
            setPasswordData({});
            props.onClose();
          } else {
            createNotification("error", "Failed To Update Password");
          }
          setBtnLoader(false);
        })
        .catch((err) => {
          console.log("error: ", err.response.data);
          if (
            err.response &&
            err.response.data.err.toLowerCase() === "password incorrect"
          ) {
            createNotification("error", "Password Incorrect");
            setError("Password Incorrect");
          } else {
            createNotification("error", "Failed To Update Password");
          }
          setBtnLoader(false);
        });
      setError("");
    } else {
      setBtnLoader(false);
    }
  };
  return (
    <OverlayBox
      open={props.onOpen}
      close={() => {
        setBtnLoader(false);
        props.onClose();
      }}
      title="Reset Password"
      customClass={style.customOverlayClass}
    >
      <div className={style.password_form}>
        <div className={style.section_box}>
          <div className={style.input_section}>
            <InputText
              type="password"
              name="old_password"
              placeholder="Enter Current Password"
              getValue={(value) => {
                setPasswordData((prev) => {
                  let newObject = { ...prev };
                  newObject["old_password"] = value;
                  return newObject;
                });
              }}
              value={passwordData.old_password}
              required={true}
              label={"Current Password"}
              customClass={style.customInputClass}
            />
            <InputText
              type="password"
              name="new_password"
              placeholder="Enter New Password"
              label={"New Password"}
              getValue={(value) => {
                setPasswordData((prev) => {
                  let newObject = { ...prev };
                  newObject["new_password"] = value;
                  return newObject;
                });
              }}
              value={passwordData.new_password}
              required={true}
              message="Atleast one special character, one numeric, one capital letter, 10 minimum length & maximum 20 length"
              customClass={style.customInputPasswordClass}
            />
            <InputText
              type="text"
              name="confirm_new_password"
              placeholder="Confirm Password"
              label={"Confirm Password"}
              getValue={(value) => {
                setPasswordData((prev) => {
                  let newObject = { ...prev };
                  newObject["confirm_new_password"] = value;
                  return newObject;
                });
              }}
              value={passwordData.confirm_new_password}
              required={true}
              customClass={style.customInputPasswordClass}
            />
            <div className={style.status}>
              {error && (
                <>
                  <div className={`${style.error_status}`}>{error}</div>
                </>
              )}
            </div>
            {btnLoader ? (
              <div className={`primary-btn ${style.button} mt-2`}>
                <BtnLoader className={style.loader} />
              </div>
            ) : (
              <button
                type="button"
                onClick={() => {
                  onUpdate();
                }}
                className={`primary-btn ${style.button} mt-2`}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </OverlayBox>
  );
};

export default ResetPassword;
